<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
                <v-btn color="secondary" icon v-on="{ ...tooltip }" @click.stop="dialog = true">
                    <v-icon dark>zoom_in</v-icon>
                </v-btn>
            </template>
            <span>Show task outcome</span>
        </v-tooltip>
        <v-dialog max-width="700" v-model="dialog">
            <v-card>
                <v-card-title>
                    <div class="secondary--text">
                        <v-icon class="mr-1">mdi-information-variant</v-icon>
                        Task outcome
                    </div>
                </v-card-title>
                <v-divider class="mb-1"></v-divider>
                <v-card-text>
                    <v-card-text>
                        <div v-html="message"></div>
                    </v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="dialog = false">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'UserTaskOutcome',
    props: {
        message: String
    },
    data() {
        return {
            dialog: false
        }
    }
}
</script>
